/**
 *
 * Copyright 2020-2025 Splunk Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import { safelyGetSessionStorage, safelySetSessionStorage } from '../storage/local-store'
import { generateId } from '../utils'

const BROWSER_INSTANCE_ID_KEY = 'browser_instance_id'

/**
 * BrowserInstanceService is responsible for generating and storing a unique ID for the tab.
 * THe ID is stored in the session storage, so stays the same even after page reload
 * as long as the tab stays on the same domain.
 * This ID will be the same for all frames/context in the tab as long as they are on the same domain.
 * Currently, this is simplified version which has this limitation:
 *  - It does not cover the case when tab is duplicated -
 *      browsers copy storage when duplicating tabs so the ID will be the same
 *    To cover this case we need to implement a communication between tabs which requires asynchronous initialization.
 *    This is not implemented yet as requires bigger refactoring.
 */
export class BrowserInstanceService {
	// `undefined` represents the case when the storage is inaccessible.
	static _id: string | undefined | null = null

	static get id(): string | undefined {
		if (this._id !== null) {
			return this._id
		}

		// Check if the ID is already stored in the session storage. It might be generated by another frame/context.
		let browserInstanceId = safelyGetSessionStorage(BROWSER_INSTANCE_ID_KEY)
		if (browserInstanceId) {
			this._id = browserInstanceId
		} else if (browserInstanceId === null) {
			// Storage is accessible but the ID is not stored yet.
			browserInstanceId = generateId(64)
			this._id = browserInstanceId
			safelySetSessionStorage(BROWSER_INSTANCE_ID_KEY, browserInstanceId)
		} else {
			// Storage is not accessible.
			this._id = undefined
		}

		return this._id
	}
}
